import { VariantProps, tv } from 'tailwind-variants';
import clsx from 'clsx';
import { Box, IBoxProps } from '@/src/packages/components/ui/Box';

const useLinkStyles = tv({
  base: 'cursor-pointer focus-visible:bg-background-secondary',
  variants: {
    variant: {
      solid: 'bg-[size:100%_1px]',
      ghost: 'bg-[size:0%_1px] hover:bg-[size:100%_1px]',
    },
  },
});

export type ILinkProps = Omit<IBoxProps, 'href'> &
  VariantProps<typeof useLinkStyles>;

export const Link = (props: ILinkProps) => {
  const { children, className, variant = 'ghost', ...rest } = props;

  return (
    <Box
      as="span"
      className={clsx(useLinkStyles({ variant }), className)}
      style={{
        backgroundImage: 'linear-gradient(currentColor, currentColor)',
        backgroundPosition: '0% 100%',
        backgroundRepeat: 'no-repeat',
        transition: 'background-size .3s',
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};
