'use client';
import { useStoreContext } from '@/src/packages/components/contexts/store-context-provider.context';
import { Box } from '@/src/packages/components/ui/Box';
import { Button } from '@/src/packages/components/ui/Button';
import { Heading } from '@/src/packages/components/ui/Heading';
import { Link } from '@/src/packages/components/ui/Link';
import { Wordmark } from '@/src/packages/components/ui/Logo';
import { Paragraph } from '@/src/packages/components/ui/Paragraph';
import { Stack } from '@/src/packages/components/ui/Stack';
import { paths } from '@/src/packages/routes';
import { pathNames } from '@/src/packages/routes/pathNames.enum';
import { logger } from '@/src/packages/server/clients/logger';
import { getRoute } from '@/src/packages/utils/routes';
import NextLink from 'next/link';
import { useEffect } from 'react';

const ErrorPage = ({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) => {
  useEffect(() => {
    console.log(error);
    logger.error(error.message);
  }, [error]);

  return (
    <Box className="flex h-screen w-screen flex-col p-12 text-center">
      <NextLink
        className="mx-auto flex w-[240px] items-center"
        href={getRoute({ route: paths.HOME_INDEX })}
      >
        <Wordmark />
      </NextLink>
      <Box className="flex flex-1 flex-col items-center justify-center">
        <Stack spaceY={6}>
          <Stack spaceY={3}>
            <Heading as="h1" size="h1" className="font-serif font-medium">
              Something has gone wrong
            </Heading>
            <Paragraph>
              Try reloading the page. If this error consists, please{' '}
              <Link
                className="cursor-pointer underline underline-offset-4"
                asChild
              >
                <NextLink href={getRoute({ route: paths.CONTACT_INDEX })}>
                  contact us
                </NextLink>
              </Link>
              .
            </Paragraph>
          </Stack>
          <Button isBlock onClick={() => reset()}>
            Reload page
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export { ErrorPage };
